var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"retailModal","tabindex":"-1","role":"dialog","aria-labelledby":"contact","aria-hidden":"true"}},[_c('SenderIDModal',{ref:"senderID",staticClass:"sender-id-modal",on:{"hideModal":_vm.hideSenderIDModal}}),_c('div',{staticClass:"modal-dialog modal-dialog-centered retailer-modal",attrs:{"role":"document"}},[(_vm.success)?_c('div',{staticClass:"modal-content d-flex align-items-center"},[_c('successMessage',{attrs:{"message":_vm.success}}),_c('button',{staticClass:"__close__success__modal",on:{"click":_vm.hideModal}},[_vm._v(" "+_vm._s(_vm.$t("misc.button.close"))+" ")])],1):_vm._e(),(!_vm.success)?_c('div',{staticClass:"modal-content pb-3"},[_c('div',{staticClass:"modal-header pt-4 pb-2"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.account_settings.reseller.form.details"))+" ")])]),_c('div',{staticClass:"modal-body m-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData,"rules":_vm.rules},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.checkEnter.apply(null, arguments)}}},[_c('div',{staticClass:"row justify-content-center contains-left-right"},[_c('div',{staticClass:"left-side"},[_c('div',{staticClass:"col-md-12 form_input_container"},[_c('el-form-item',{attrs:{"label":_vm.$t(
                          'dashboard.account_settings.reseller.form.domain.label'
                        ),"prop":"domain"}},[_c('el-input',{attrs:{"type":"text","placeholder":_vm.$t(
                            'dashboard.account_settings.reseller.form.domain.placeholder'
                          )},model:{value:(_vm.formData.domain),callback:function ($$v) {_vm.$set(_vm.formData, "domain", $$v)},expression:"formData.domain"}})],1)],1),_c('div',{staticClass:"col-md-12 form_input_container"},[_c('el-form-item',{attrs:{"label":_vm.$t(
                          'dashboard.account_settings.reseller.form.company.label'
                        ),"prop":"company_name"}},[_c('el-input',{attrs:{"type":"text","placeholder":_vm.$t(
                            'dashboard.account_settings.reseller.form.company.placeholder'
                          )},model:{value:(_vm.formData.company_name),callback:function ($$v) {_vm.$set(_vm.formData, "company_name", $$v)},expression:"formData.company_name"}})],1)],1),_c('div',{staticClass:"col-md-12 form_input_container"},[_c('el-form-item',{attrs:{"label":_vm.$t(
                          'dashboard.account_settings.reseller.form.phone.label'
                        ),"prop":"phone"}},[_c('el-input',{attrs:{"type":"text","placeholder":_vm.$t(
                            'dashboard.account_settings.reseller.form.phone.placeholder'
                          )},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1)],1)]),_c('div',{staticClass:"right-side"},[_c('div',{staticClass:"col-md-12 __enable_sections d-flex align-items-center"},[_c('div',{staticClass:"my-3 form_input_container my_form_input_container select-sender-container"},[_c('el-form-item',{attrs:{"label":_vm.$t(
                            'dashboard.account_settings.reseller.form.sender_id.label'
                          ),"prop":"sender_id"}},[_c('el-select',{staticStyle:{"width":"fit-content","height":"48px"},attrs:{"id":"_select_sender_id","filterable":"","placeholder":_vm.$t(
                              'dashboard.account_settings.reseller.form.sender_id.placeholder'
                            )},model:{value:(_vm.formData.sender_id),callback:function ($$v) {_vm.$set(_vm.formData, "sender_id", $$v)},expression:"formData.sender_id"}},_vm._l((_vm.senderIDs),function(senderid,key){return _c('el-option',{key:key,staticClass:"notranslate",attrs:{"label":senderid.sender_id,"value":senderid.id}},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"width":"100%"}},[_c('div',[_vm._v(_vm._s(senderid.sender_id))]),_c('img',{staticStyle:{"margin-left":"2em","width":"18px","height":"18px"},attrs:{"src":require("@/assets/Shield Done.svg"),"alt":""}})])])}),1)],1),_c('el-button',{staticClass:"create-sender-id-button",staticStyle:{"width":"fit-content"},on:{"click":_vm.showSenderIDModal}},[_vm._v(_vm._s(_vm.$t( "dashboard.account_settings.reseller.form.sender_id.button" )))])],1)]),_c('div',{staticClass:"col-md-12 uploader-container"},[_c('el-form-item',{staticStyle:{"margin-bottom":"0px","padding-bottom":"0px"},attrs:{"required":"","label":_vm.$t(
                          'dashboard.account_settings.reseller.form.logo.label'
                        )}}),_c('el-form-item',{staticClass:"uploader-container-inner",attrs:{"prop":"logo","required":""}},[_c('el-upload',{staticClass:"avatar-uploader",staticStyle:{"cursor":"pointer","position":"relative","overflow":"hidden","max-width":"100% !important","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"},attrs:{"action":"#","show-file-list":true,"auto-upload":false,"accept":".jpeg,.png,.jpg,.gif,.svg","multiple":false,"limit":1,"on-exceed":_vm.handleFileExceed,"on-change":_vm.handleChange,"drag":""}},[_c('div',{staticClass:"el-upload__text upload-text border-container"},[_c('img',{attrs:{"src":require("../../../assets/Image 2.svg"),"alt":""}}),_c('p',{staticClass:"drag_file"},[_vm._v(" "+_vm._s(_vm.$t( "dashboard.account_settings.reseller.form.logo.text" ))+" "),_c('em',[_vm._v(_vm._s(_vm.$t( "dashboard.account_settings.reseller.form.logo.link" ))+" browse")])]),_c('p',{staticClass:"file_type mb-3"},[_vm._v(" "+_vm._s(_vm.$t( "dashboard.account_settings.reseller.form.logo.sub_text" ))+" ")])])])],1),(_vm.fileError)?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.fileError)+" ")]):_vm._e()],1)])])]),(_vm.errors.length)?_c('div',{staticStyle:{"margin-top":"2em"}},_vm._l((_vm.errors),function(error,key){return _c('Alert',{key:key,attrs:{"message":error.toString(),"type":_vm.type}})}),1):_vm._e()],1)]),_c('div',{staticClass:"row justify-content-center mb-4"},[_c('div',{staticClass:"col-md-12 modal__footer__btns d-flex justify-content-between buttons-container notranslate"},[_c('button',{staticClass:"cancel notranslate",staticStyle:{"width":"123px"},on:{"click":_vm.hideModal}},[_vm._v(" "+_vm._s(_vm.$t("misc.button.cancel"))+" ")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading,"id":"cancelinput"},on:{"click":function($event){return _vm.submitAction('ruleForm')}}},[_vm._v(" "+_vm._s(_vm.$t("misc.button.submit"))+" ")])],1)])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }