<template>
  <div
    class="modal fade"
    id="retailModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contact"
    aria-hidden="true">
    <SenderIDModal
      ref="senderID"
      @hideModal="hideSenderIDModal"
      class="sender-id-modal" />

    <div
      class="modal-dialog modal-dialog-centered retailer-modal"
      role="document">
      <div class="modal-content d-flex align-items-center" v-if="success">
        <successMessage :message="success" />
        <button @click="hideModal" class="__close__success__modal">
          {{ $t("misc.button.close") }}
        </button>
      </div>
      <div class="modal-content pb-3" v-if="!success">
        <div class="modal-header pt-4 pb-2">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("dashboard.account_settings.reseller.form.details") }}
          </h5>
        </div>
        <div class="modal-body m-0">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <el-form
                :model="formData"
                :rules="rules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter">
                <div class="row justify-content-center contains-left-right">
                  <div class="left-side">
                    <div class="col-md-12 form_input_container">
                      <el-form-item
                        :label="
                          $t(
                            'dashboard.account_settings.reseller.form.domain.label'
                          )
                        "
                        prop="domain">
                        <el-input
                          type="text"
                          :placeholder="
                            $t(
                              'dashboard.account_settings.reseller.form.domain.placeholder'
                            )
                          "
                          v-model="formData.domain" />
                      </el-form-item>
                    </div>
                    <div class="col-md-12 form_input_container">
                      <el-form-item
                        :label="
                          $t(
                            'dashboard.account_settings.reseller.form.company.label'
                          )
                        "
                        prop="company_name">
                        <el-input
                          type="text"
                          :placeholder="
                            $t(
                              'dashboard.account_settings.reseller.form.company.placeholder'
                            )
                          "
                          v-model="formData.company_name" />
                      </el-form-item>
                    </div>
                    <!-- <div class="col-md-12 form_input_container">
                                            <el-form-item label="API Key" prop="api_key">
                                                <el-input type="text" placeholder="Enter API key" v-model="formData.api_key" />
                                            </el-form-item>
                                        </div> -->
                    <div class="col-md-12 form_input_container">
                      <el-form-item
                        :label="
                          $t(
                            'dashboard.account_settings.reseller.form.phone.label'
                          )
                        "
                        prop="phone">
                        <el-input
                          type="text"
                          :placeholder="
                            $t(
                              'dashboard.account_settings.reseller.form.phone.placeholder'
                            )
                          "
                          v-model="formData.phone" />
                      </el-form-item>
                    </div>
                  </div>
                  <!-- left side end -->
                  <div class="right-side">
                    <div
                      class="col-md-12 __enable_sections d-flex align-items-center">
                      <div
                        class="my-3 form_input_container my_form_input_container select-sender-container">
                        <el-form-item
                          :label="
                            $t(
                              'dashboard.account_settings.reseller.form.sender_id.label'
                            )
                          "
                          prop="sender_id">
                          <el-select
                            id="_select_sender_id"
                            style="width: fit-content; height: 48px"
                            v-model="formData.sender_id"
                            filterable
                            :placeholder="
                              $t(
                                'dashboard.account_settings.reseller.form.sender_id.placeholder'
                              )
                            ">
                            <el-option
                              v-for="(senderid, key) in senderIDs"
                              :key="key"
                              :label="senderid.sender_id"
                              :value="senderid.id"
                              class="notranslate">
                              <div
                                style="width: 100%"
                                class="d-flex justify-content-between">
                                <div>{{ senderid.sender_id }}</div>
                                <img
                                  style="
                                    margin-left: 2em;
                                    width: 18px;
                                    height: 18px;
                                  "
                                  src="@/assets/Shield Done.svg"
                                  alt="" />
                              </div>
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-button
                          class="create-sender-id-button"
                          style="width: fit-content"
                          @click="showSenderIDModal"
                          >{{
                            $t(
                              "dashboard.account_settings.reseller.form.sender_id.button"
                            )
                          }}</el-button
                        >
                      </div>
                    </div>
                    <div class="col-md-12 uploader-container">
                      <el-form-item
                        style="margin-bottom: 0px; padding-bottom: 0px"
                        required
                        :label="
                          $t(
                            'dashboard.account_settings.reseller.form.logo.label'
                          )
                        ">
                      </el-form-item>
                      <el-form-item
                        prop="logo"
                        required
                        class="uploader-container-inner">
                        <el-upload
                          class="avatar-uploader"
                          style="
                            cursor: pointer;
                            position: relative;
                            overflow: hidden;
                            max-width: 100% !important;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          "
                          action="#"
                          :show-file-list="true"
                          :auto-upload="false"
                          accept=".jpeg,.png,.jpg,.gif,.svg"
                          :multiple="false"
                          :limit="1"
                          :on-exceed="handleFileExceed"
                          :on-change="handleChange"
                          drag>
                          <div
                            class="el-upload__text upload-text border-container">
                            <img src="../../../assets/Image 2.svg" alt="" />
                            <p class="drag_file">
                              {{
                                $t(
                                  "dashboard.account_settings.reseller.form.logo.text"
                                )
                              }}
                              <em
                                >{{
                                  $t(
                                    "dashboard.account_settings.reseller.form.logo.link"
                                  )
                                }}
                                browse</em
                              >
                            </p>
                            <p class="file_type mb-3">
                              {{
                                $t(
                                  "dashboard.account_settings.reseller.form.logo.sub_text"
                                )
                              }}
                            </p>
                          </div>
                        </el-upload>
                      </el-form-item>
                      <p class="text-danger" v-if="fileError">
                        {{ fileError }}
                      </p>
                    </div>
                  </div>
                </div>
              </el-form>
              <div v-if="errors.length" style="margin-top: 2em">
                <Alert
                  v-for="(error, key) in errors"
                  :key="key"
                  :message="error.toString()"
                  :type="type" />
              </div>
            </div>
          </div>
          <div class="row justify-content-center mb-4">
            <div
              class="col-md-12 modal__footer__btns d-flex justify-content-between buttons-container notranslate">
              <button
                style="width: 123px"
                class="cancel notranslate"
                @click="hideModal">
                {{ $t("misc.button.cancel") }}
              </button>
              <el-button
                style=""
                type="primary"
                :loading="loading"
                id="cancelinput"
                @click="submitAction('ruleForm')">
                {{ $t("misc.button.submit") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Storage from "@/services/storage.js";
  import SenderIDModal from "../../dashboard/main/senderIDModal.vue";
  import store from "@/state/store.js";
  import $ from "jquery";
  export default {
    components: {
      SenderIDModal: SenderIDModal,
    },
    data() {
      return {
        success: "",
        errors: [],
        loading: false,
        type: "alert-danger",
        fileError: "",
        logo: "",
        formData: {
          domain: "",
          logo: {},
          phone: "",
          api_key: "",
          company_name: "",
          sender_id: "",
        },
        rules: {
          phone: [
            {
              required: true,
              message: "Phone number is required.",
              trigger: "blur",
            },
            {
              pattern: /^[0-9]{10}$/g,
              message: "Phone number must be 10 digits",
            },
          ],
          company_name: [
            {
              required: true,
              message: "Company Name is required.",
              trigger: "change",
            },
          ],
          domain: [
            {
              required: true,
              message: "Domain is required.",
              trigger: "change",
            },
          ],
          sender_id: [
            {
              required: true,
              message: "Sender ID is required.",
              trigger: "change",
            },
          ],
          api_key: [
            {
              required: true,
              message: "API key is required.",
              trigger: "change",
            },
          ],
        },
      };
    },
    computed: {
      senderIDs() {
        return store.getters["senderid/senderIDs"];
      },
      corporate() {
        const storage = new Storage();
        return storage.getSavedState("isCorporate");
      },
    },
    methods: {
      handleFileExceed() {
        this.$message.error("You can only upload one file");
      },
      handleChange(file, fileList) {
        this.formData.logo = file.raw;
        this.logo = file.raw;
      },
      hideModal() {
        $("#retailModal").modal("hide");
        this.error = "";
        this.success = "";
        this.loading = false;
        Object.keys(this.formData).forEach((key) => {
          this.formData[key] = "";
          if (key == "logo") {
            this.formData[key] = {};
          }
        });
      },
      hideSenderIDModal() {
        let element = this.$refs.senderID.$el;
        $(element).modal("hide");
      },
      submitAction(formName) {
        this.errors = [];
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const formData = new FormData();
            const { domain, logo, api_key, phone, company_name, sender_id } =
              this.formData;

            formData.append("api_key", api_key);
            formData.append("domain", domain);
            formData.append("logo", logo);
            formData.append("phone", phone);
            formData.append("company_name", company_name);
            formData.append("sender_id", sender_id);
            return store
              .dispatch("auth/createRetailerAccount", formData)
              .then((message) => {
                this.success = message;
              })
              .catch((err) => {
                if (err?.response?.data == undefined) {
                  this.errors = "Network error try again.";
                }
                if (err.response && err.response.status === 404) {
                  this.errors = Object.values(err.response.data.data);
                }
              })
              .finally(() => (this.loading = false));
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      showSenderIDModal() {
        store.dispatch("senderid/setSenderModalState", {
          title: "Register a new Sender ID",
          type: "newSenderID",
          btnTitle: "Register new Send ID",
        });
        let element = this.$refs.senderID.$el;
        $(element).modal("show");
      },
    },
    mounted() {
      this.error = "";
      this.success = "";
      this.loading = false;
      this.formData = {
        api_key: "",
        sender_id: "",
        api_key: "",
        domain: "",
        logo: "",
        company_name: "",
      };
      //   store.dispatch('admin/massMessage/getIndustries').then( data => console.info(data))
    },
  };
</script>
<style scoped>
  .file_type {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #8a8c8f;
  }

  .text_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
  }

  .retailer-modal {
    /* background-color: springgreen; */
    min-width: 70vw !important;
  }

  .contains-left-right {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    /* background-color: yellow; */
  }

  .left-side {
    width: 40%;
  }

  .right-side {
    width: 40%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .right-side * {
    margin: 0 !important;
    /* padding: 0 !important; */
  }

  .select-sender-container {
    padding-bottom: 1.2rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }

  .buttons-container {
    max-width: 40% !important;
    display: flex;
    padding-top: 1.5rem;
    margin-top: 1rem;
  }

  .buttons-container button {
    width: 30%;
    margin-inline: 0.5rem;
  }

  .create-sender-id-button {
    background: #f79b31;
    border-radius: 4px;
    width: 40%;
    height: 100%;
    border: none;
    color: #ffffff;
    font-size: clamp(0.5rem, 0.9vw, 1rem);
    line-height: 22px;
    text-align: center;
    margin: 0 auto;
  }

  @media screen and (max-width: 1024px) {
    .contains-left-right {
      flex-direction: column;
    }

    .left-side {
      width: 100%;
    }

    .right-side {
      width: 100%;
    }

    .right-side * {
      /* margin: 1em !important; */
      padding-inline: 5px !important;
    }

    .buttons-container button {
      width: 100% !important;
    }

    .buttons-container {
      max-width: 100% !important;
    }

    .select-sender-container {
      width: 100%;
    }

    .uploader-container {
      width: 100%;
    }
  }
  .sender-id-modal {
    background-color: #0d0d0cb7;
  }
</style>
